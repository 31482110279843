@tailwind base;
@tailwind components;
@tailwind utilities;
 

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 262.1 83.3% 57.8%;
    --primary-foreground: 210 20% 98%;
    /* --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%; */
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 262.1 83.3% 57.8%;
    --radius: 0.3rem;
  }
 
  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    /* --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%; */
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
  }
}

 
@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    overflow-y: scroll;
  }

  .rules_content p {
    @apply mb-4;
  }

  .rules_content ol {
    @apply list-decimal pl-8 mb-4;
  }

  .rules_content li {
    @apply mb-1;
  }

  .force-ltr {
    direction: ltr !important;
  }
}

.react-datepicker-time__input input {
  text-align: center;
  background-color: transparent;
  border: 1px solid #00000054;
  outline: none;
  border-radius: 5px;
  padding-left: 10px;
}

.bg-image-curve {
  background-image: url("https://cdngarenanow-a.akamaihd.net/gstaticid/FF_ME/cb/bg_wave 2.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}


.react-datepicker__time-list::-webkit-scrollbar {
  width: 0.1rem;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background-color: #091e2586;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
  filter: grayscale(1);
}